import './App.css';
import Nav from './Nav';
import Table1 from  './Table1';

function App() {
  return (
    <div className="App">
      <header className="App-header">
              <Nav />
      </header>
      <body>
      <Table1 />
      </body>
    </div>
  );
}

export default App;
